<style lang="scss" scoped></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.731 4.61848C19.5677 2.97912 16.2377 2 12.8226 2C8.5998 2 5.15851 3.4743 3.38285 6.04527C2.54878 7.25197 2.08736 8.68251 2.01108 10.2944C1.94356 11.7299 2.1849 13.318 2.7276 15.0261C4.5808 9.46905 9.75774 5.11867 15.7262 5.11867C15.7262 5.11867 10.1416 6.58797 6.63031 11.1409C5.82912 12.1733 5.18601 13.3192 4.7221 14.541C3.73041 16.9026 3.22025 19.4386 3.22154 22H5.72247C5.72247 22 5.34233 19.6116 6.00258 16.8656C6.97901 17.0049 7.96365 17.0788 8.94993 17.0869C11.2495 17.0869 12.8839 16.5892 14.0956 15.5213C15.181 14.5647 15.78 13.278 16.414 11.9175C17.3818 9.83794 18.4785 7.48206 21.6635 5.66262C21.7533 5.61137 21.829 5.53862 21.8839 5.45089C21.9387 5.36317 21.9709 5.26321 21.9776 5.15998C21.9843 5.05675 21.9652 4.95347 21.9222 4.8594C21.8792 4.76533 21.8135 4.68341 21.731 4.62098V4.61848Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconVeggie",
  props: {
    size: {
      type: [String, Number],
      default: 24
    }
  }
};
</script>
