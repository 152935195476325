<style lang="scss" scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8091 8.608C17.1652 2.46287 6.8321 2.46512 0.190843 8.608C-0.0589069 8.839 -0.063782 9.23013 0.177718 9.46975L1.46172 10.7436C1.69197 10.9724 2.06247 10.9773 2.30172 10.7579C7.77372 5.74488 16.2255 5.74375 21.6986 10.7579C21.9379 10.9773 22.3084 10.972 22.5386 10.7436L23.8226 9.46975C24.0637 9.23013 24.0589 8.839 23.8091 8.608ZM12 16C10.6743 16 9.59997 17.0744 9.59997 18.4C9.59997 19.7256 10.6743 20.8 12 20.8C13.3256 20.8 14.4 19.7256 14.4 18.4C14.4 17.0744 13.3256 16 12 16ZM19.6001 12.8654C15.2779 9.043 8.71722 9.04713 4.39985 12.8654C4.1411 13.0941 4.13285 13.4913 4.37847 13.7335L5.66997 15.0081C5.89497 15.2301 6.25722 15.2451 6.49685 15.0381C9.64497 12.3168 14.3621 12.3228 17.5027 15.0381C17.7424 15.2451 18.1046 15.2305 18.3296 15.0081L19.6211 13.7335C19.8671 13.4913 19.8585 13.0938 19.6001 12.8654V12.8654Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
