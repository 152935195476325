<style lang="scss" scoped></style>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 28L22.0187 22.008L28 28ZM25.3334 14C25.3334 17.0058 24.1393 19.8885 22.0139 22.0139C19.8885 24.1393 17.0058 25.3334 14 25.3334C10.9942 25.3334 8.11156 24.1393 5.98614 22.0139C3.86073 19.8885 2.66669 17.0058 2.66669 14C2.66669 10.9942 3.86073 8.11156 5.98614 5.98614C8.11156 3.86073 10.9942 2.66669 14 2.66669C17.0058 2.66669 19.8885 3.86073 22.0139 5.98614C24.1393 8.11156 25.3334 10.9942 25.3334 14V14Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M14 14H9.33337M14 9.33337V14V9.33337ZM14 14V18.6667V14ZM14 14H18.6667H14Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>
