<style lang="scss" scoped></style>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8812 8.88323C14.8812 6.69794 11.4747 5.98024 11.4747 5.98024C11.1281 5.98024 10.6744 5.97823 9.60029 5.98024V4.93283L10.6627 4.9459V3.0481H9.60029V4.05329H6.39066V3.0481H5.32079V4.9459L6.4356 4.93283V5.98024H4.43921C4.43921 5.98024 1.04022 6.62054 1.04022 8.87418C1.04022 11.1288 0.0302582 14.6832 0.0302582 14.6832C0.0302582 14.6832 0.817686 14.7314 0.817686 16H15.0823C15.0823 14.7857 16.0003 14.6782 16.0003 14.6782C16.0003 14.6782 14.8812 11.0685 14.8812 8.88323V8.88323ZM8.01794 15.1104C6.99503 15.1103 6.01407 14.7283 5.29086 14.0487C4.56765 13.369 4.16144 12.4472 4.16158 11.4862C4.16172 10.5251 4.56821 9.60345 5.29162 8.92397C6.01503 8.24448 6.9961 7.86283 8.01901 7.86296C8.52551 7.86303 9.02703 7.95682 9.49494 8.13899C9.96286 8.32116 10.388 8.58814 10.7461 8.92468C11.1042 9.26122 11.3882 9.66073 11.582 10.1004C11.7758 10.5401 11.8754 11.0113 11.8754 11.4872C11.8753 11.9631 11.7755 12.4343 11.5816 12.8739C11.3877 13.3135 11.1035 13.7129 10.7453 14.0494C10.3871 14.3858 9.96192 14.6527 9.49396 14.8347C9.02599 15.0168 8.52444 15.1105 8.01794 15.1104V15.1104Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33289 4.8535H2.72908C2.72908 4.8535 4.08354 5.02438 4.12526 4.03125H0.0233612C0.0233612 4.96105 1.33289 4.8535 1.33289 4.8535Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.25061 2.252C4.25061 1.91024 5.12791 1.95949 5.49702 1.95949H10.5404C10.9063 1.95949 11.7558 2.00875 11.7558 2.35051V2.85612H15.9786C15.9786 2.84607 15.9807 2.86416 15.9807 2.85612C15.9807 -0.0971296 9.80754 0.000374094 7.64319 0.000374094C5.48097 0.000374094 0 -0.0740102 0 2.88125H4.25061C4.25275 2.86014 4.25061 2.06504 4.25061 2.252Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2815 4.8535H14.6541C14.6541 4.8535 15.9615 5.04649 15.9776 4.03125H11.8853C11.9366 4.91582 13.2815 4.8535 13.2815 4.8535V4.8535Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.53008 11.0061H10.6698V11.9751H8.53008V11.0061Z"
      fill="currentColor"
    />
    <path
      d="M7.97063 13.0005C8.84336 13.0005 9.55084 12.329 9.55084 11.5007C9.55084 10.6724 8.84336 10.001 7.97063 10.001C7.09791 10.001 6.39043 10.6724 6.39043 11.5007C6.39043 12.329 7.09791 13.0005 7.97063 13.0005Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPhone"
};
</script>
