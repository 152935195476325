<style lang="scss" scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.40032 4.80015C10.7258 4.80015 11.8004 3.7256 11.8004 2.40007C11.8004 1.07455 10.7258 0 9.40032 0C8.07479 0 7.00024 1.07455 7.00024 2.40007C7.00024 3.7256 8.07479 4.80015 9.40032 4.80015Z"
      fill="currentColor"
    />
    <path
      d="M18.9766 15.3647C18.9223 15.0927 18.7754 14.848 18.561 14.6721C18.3466 14.4962 18.0779 14.4 17.8006 14.3998H12.8408L12.326 10.7997H17.8006V8.39965H11.984L11.7884 7.0292C11.7474 6.74336 11.6047 6.48188 11.3865 6.29276C11.1683 6.10363 10.8891 5.99953 10.6004 5.99957H9.40034C9.22852 6.00014 9.05881 6.03749 8.90262 6.10911C8.74644 6.18073 8.6074 6.28496 8.49485 6.41478C8.3823 6.54461 8.29885 6.69703 8.25012 6.8618C8.20138 7.02656 8.18849 7.19985 8.2123 7.37002L9.26473 14.7406C9.34887 15.3114 9.6349 15.8331 10.0709 16.2109C10.5069 16.5888 11.0639 16.7978 11.6408 16.7999H16.8166L17.8234 21.8353C17.9362 22.3957 18.4282 22.8001 19.0006 22.8001H22.6007V20.4H19.9835L18.9766 15.3647V15.3647Z"
      fill="currentColor"
    />
    <path
      d="M13.6124 18.5998C12.7256 20.3711 10.9123 21.5999 8.80024 21.5999C7.36852 21.5983 5.99589 21.0289 4.9835 20.0165C3.97112 19.0041 3.40166 17.6315 3.40008 16.1997C3.40209 15.1245 3.72485 14.0743 4.32706 13.1835C4.92927 12.2927 5.78354 11.6018 6.78058 11.1992L6.43377 8.7691C3.28607 9.77354 1 12.7244 1 16.1997C1 20.5007 4.49931 24 8.80024 24C10.138 23.9981 11.4529 23.6521 12.6182 22.9951C13.7836 22.3381 14.7603 21.3923 15.4545 20.2487L15.1256 18.5998H13.6124Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
